import { GetterTree } from 'vuex'
import StateRun from './types'

export default {
  featureMode: (state, getters, rootState, rootGetters) => {
    const features = rootGetters['auth/features']
    if (features.includes('course-management') && features.includes('event-management')) {
      return 'runs'
    } else if (features.includes('course-management')) {
      return 'courses'
    } else if (features.includes('event-management')) {
      return 'events'
    }
    return 'none'
  }
} as GetterTree<StateRun, any>
