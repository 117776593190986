import { MutationTree } from 'vuex'
import StateRun from './types'
import { MUTATIONS } from './consts'

const mutations = {
  [MUTATIONS.SET_REGISTERED_AS_NEW_USER]: (state, payload) => {
    state.registeredAsNewUser = payload
  }
} as MutationTree<StateRun>

export default mutations
